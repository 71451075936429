<template>
    <div>
        <div class="header-row">
            <div class="heade-div">
                <div class="logo" @click="$router.push('/')">
                    <img style="height: 60px;margin-right: 12px" :src="preFix + appInfo.pc_logo">
<!--                    <p class="title">{{appInfo.app_name}}</p>-->
                </div>
                <div class="disCenter height100">
                    <div class="homePage"  style="margin-right: 50px;cursor: pointer;" @click="$router.push('/')">
                        <img src="../assets/homeIcon.png" style="width: 22px;height: 21px;margin-right: 10px" alt="">
                        主页
                    </div>
                    <!--                   <div class="down" :class="$route.path == '/merchant' ? 'homePage1' : ''" style="cursor:pointer;" @click="$router.push('/merchant')">-->
                    <!--                        APP下载-->
                    <!--                   </div>-->
                </div>
            </div>
        </div>

        <div class="header">
            <div class="text">
                矩阵通达人带货小程序
            </div>
            <div class="disBetween header-down">
                <div></div>

                <div class="code-row">
                    <img :src="preFix + appInfo.xcx_ewm" alt="">
                    <span class="open">扫码关注</span>
                </div>
            </div>
        </div>
        <div class="ref-content">
            <img class="res-images" src="../assets/ifone.png" alt="">

            <div class="dr-bgc">
                <div class="dr-view">
                    <div class="target1"></div>
                    <div class="target2"></div>
                    <div class="target3"></div>
                    <div class="target4"></div>
                    <div class="target5"></div>
                    <div class="target6"></div>
                    <div class="target7"></div>
                    <div class="target8"></div>
                    <div class="target9"></div>
                </div>
            </div>
        </div>
        <div style="background-color:#F5F5F5;width: 100%;">
            <div class="res-bgc1">
                <img class="res-img" src="../assets/20210924181911.png" alt="">
            </div>

        </div>

    </div>
</template>

<script>
    import api from "../lnit/api";

    export default {
        name: "intelligent",
        data(){
            return{
                appInfo:{},
                preFix:'',
            }
        },
        created() {
            this.preFix = api.url_prefix
            this.getAppInfoFn()
        },
        methods:{
            getAppInfoFn(){
                this.$get({
                    url:api.app_info
                }).then((res)=>{
                    this.appInfo = res.data.app_info
                })
            },
        }
    }
</script>

<style scoped lang="scss">
    .header{
        position: relative;
        width: 100%;
        background: url("../assets/jiemian.jpg") no-repeat;
        background-size: cover;
        padding: 1px 0;
        box-sizing: border-box;
        height: 680px;
    }
    .text{
        font-size: 60px;
        font-weight: bold;
        position: absolute;
        color: white;
        left: 380px;
        top: 290px;
    }
    .target1{
        position: absolute;
        left: 50%;
        bottom: 30px;
        transform: translateX(-50%);
        z-index:100;
        width: 107px;
        height: 107px;
        transition: 0.5s;
        border-radius: 50%;
        background: url("../assets/list1.png") no-repeat;
        background-size: cover;
    }
    .target1:hover{
        width: 120px;
        height: 120px;
    }
    .target2{
        position: absolute;
        right: -33px;
        top: 47%;
        transform: translateY(-50%);
        z-index:100;
        width: 107px;
        height: 107px;
        transition: 0.5s;
        border-radius: 50%;
        background: url("../assets/list2.png") no-repeat;
        background-size: cover;
    }
    .target2:hover{
        width: 120px;
        height: 120px;
    }
    .target3{
        position: absolute;
        left: -33px;
        top: 55%;
        transform: translateY(-50%);
        z-index:100;
        width: 107px;
        height: 107px;
        transition: 0.5s;
        border-radius: 50%;
        background: url("../assets/list3.png") no-repeat;
        background-size: cover;
    }
    .target3:hover{
        width: 120px;
        height: 120px;
    }
    .target4{
        position: absolute;
        left: -80px;
        top: 45%;
        transform: translateY(-50%);
        z-index:100;
        width: 20px;
        height: 20px;
        transition: 0.5s;
        border-radius: 50%;
        background-color: #CACACA;
    }
    .target4:hover{
        width: 30px;
        height: 30px;
    }
    .target5{
        position: absolute;
        left: -50px;
        top: 70%;
        transform: translateY(-50%);
        z-index:100;
        width: 34px;
        height: 34px;
        transition: 0.5s;
        border-radius: 50%;
        background: linear-gradient(117deg, #EFFE86, #42D0FF);
    }
    .target5:hover{
        width: 44px;
        height: 44px;
    }
    .target6{
        position: absolute;
        left: 30%;
        top: 76%;
        transform: translateY(-50%);
        z-index:100;
        width: 21px;
        height: 21px;
        transition: 0.5s;
        border-radius: 50%;
        background: rgba(255,162,74,0.37);
    }
    .target6:hover{
        width: 31px;
        height: 31px;
    }
    .target7{
        position: absolute;
        left: 65%;
        top: 95%;
        transform: translateY(-50%);
        z-index:100;
        width: 21px;
        height: 21px;
        transition: 0.5s;
        border-radius: 50%;
        background: linear-gradient(117deg, #FED286, #88FF9C);
    }
    .target7:hover{
        width: 31px;
        height: 31px;
    }
    .target8{
        position: absolute;
        right:-50px;
        top: 60%;
        transform: translateY(-50%);
        z-index:100;
        width: 26px;
        height: 26px;
        transition: 0.5s;
        border-radius: 50%;
        background: linear-gradient(117deg, #FE86E6, #FFC388);
    }
    .target8:hover{
        width: 36px;
        height: 36px;
    }
    .target9{
        position: absolute;
        right:-10px;
        top: 25%;
        transform: translateY(-50%);
        z-index:100;
        width: 21px;
        height: 21px;
        transition: 0.5s;
        border-radius: 50%;
        background-color: #CACACA;
    }
    .target9:hover{
        width: 31px;
        height: 31px;
    }
    .logo{
        cursor: pointer;
        display: flex;
        align-items: center;
        img{
            height: 40px!important;
        }
    }
    .disBetween{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .homePage{
        position: relative;
        color: white;
        height: 100%;
        font-size: 20px;
        display: flex;
        align-items: center;
        line-height: 20px;
    }
    .homePage1::after{
        content: '';
        width: 60px;
        height: 8px;
        background: #FFFFFF;
        border-radius: 5px;
        position: absolute;
        bottom: -4px;
        left: 50%;
        transform: translateX(-50%);
    }
    .header-row{
        position: absolute;
        top: 0;
        color: #fff;
        left: 0;
        width: 100%;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        /*padding: 0 120px;*/
        box-sizing: border-box;
        background-color: transparent;
        z-index: 100;
        border-bottom: 1px solid rgba(255,255,255,0.6);
        .heade-div{
            width: 1200px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    .header-down{
        display: flex;
        justify-content: space-between;
        /*align-items: center;*/
        width: 1200px;
        height: 100%;
        margin: 50px auto 0;
        .phone{
            width: 500px;
            height: 671px;
            margin-left:100px ;
            /*box-shadow: 10px 10px 10px black;*/
        }
        .code-row{
            display: flex;
            flex-direction: column;
            color: white;
            width: 308px;
            height: 562px;
            background: url("../assets/phone-bg.png") no-repeat;
            background-size: cover;
            position: relative;
            img{
                position: absolute;
                bottom: 130px;
                left: 50%;
                border-radius: 20px;
                transform: translateX(-50%);
                width: 130px;
                height: 130px;
            }
            span{
                position: absolute;
                left: 50%;
                bottom: 81px;
                transform: translateX(-50%);
                font-size: 22px;
                font-family: Microsoft YaHei;
                font-weight: 400;
            }
            h3{
                font-size: 48px;
            }
            .open{
                font-size: 18px;
                text-indent: 5px;
            }
        }
    }
    .ref-content{
        display: flex;
        width: 100%;
        /*height: 800px;*/
    }
    .ref-content-bottom{
        display: flex;
        width: 100%;
        height: 800px;
        padding-top: 170px;
    }
    .res-bgc1{
        width: 100%;
        height: 624px;
        background: url("../assets/res-bgc1.png") no-repeat;
        background-size: cover;
    }
    .ref-text {
        width: 580px;
        padding-top: 118px;
        h3{
            color: #222;
            font-size: 48px;
            line-height: 64px;
            letter-spacing: 1px;
            font-weight: 600;
        }
        p{
            color: #777777;
            font-size: 26px;
            line-height: 48px;
            font-weight: 200;
            margin-top: 40px;
        }
    }
    .ref-content{
        position: relative;
        width: 100%;
        margin: 0 auto;
        background-color: #FFFBF3;
        .dr-bgc{
            position: absolute;
            width: 380px;
            height: 517px;
            background: url("../assets/dr-bgc.png")no-repeat;
            background-size: 380px,517px;
            right: 465px;
            top: 80px;
            .dr-view{
                width: 100%;
                height: 100%;

            }
        }
    }
    .ref-content .res-images {
        width: 100%;
        height: 624px;
    }
    .res-img{
        width: 400px;
        height: 517px;
        padding-left:462px;
        padding-top: 52px;
    }
</style>
